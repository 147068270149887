import React from "react"
import styled, {keyframes} from 'styled-components';
import CodeBlock from './codeblock';

const Container = styled.div`
  border-radius: 6px;
  background: #242424;
  font-family: 'Source Code Pro', monospace;
  font-size: 14px;
`;

const CodeContainer = styled.div`
  padding: 12px;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #565656 #242424;
  &::-webkit-scrollbar {
    height: 11px;
  }
  &::-webkit-scrollbar-track {
    background: #242424;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #565656;
    border-radius: 6px;
    border: 3px solid #242424;
  }
`

const RowNr = styled.p`
  color: ${props => props.focused ? '#C9DDFF' : '#5e6673'};
  margin: 0;
  padding: 0;
  text-align: right;
  font-size: 15px;
  line-height: 20px;
`;

const CodeRow = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  padding: 2px;
  overflow-x: visible;
  align-items: center;
`;

const TypingAnim = keyframes`
    0% {
      opacity:0;
      width: 0;
    }
  1% {
    opacity:1;
    }
    100% {
      opacity:1;
    }
`

const CursorAnim = keyframes`
    0% {
      opacity:0;
    }
    5% { /* still have 0 opacity until 5% here, cuz otherwise flickers in chrome */
      opacity: 0;
    }
    6% {
      opacity:1;
      }
    99% {
    opacity:1;
    }
    100% {
      opacity:0;
    }
`

const OutAnim = keyframes`
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`

const AnimatedCodeBlock = styled(CodeBlock)`
  animation: '${TypingAnim}' ${props => `${props.animDuration}s`} steps(${props => Math.ceil(props.animSteps*0.95)}, end);
  width: ${props => `${props.animSteps*8.4}px`};
  animation-fill-mode: forwards;
  animation-delay: ${props => `${props.animDelay}s`};
  white-space: nowrap;
  opacity: 0;
  animation-play-state: ${props => props.animState};
`;

const OutRow = styled.div`
  animation: '${OutAnim}';
  animation-duration: ${props => `${props.animDuration}s`};
  animation-delay: ${props => `${props.animDelay}s`};
  opacity: 0;
  animation-play-state: ${props => props.animState};
  animation-fill-mode: forwards;
`;

const Cursor = styled.div`
   opacity: 0;
   background-color: white;
   width: 2px;
   height: 16px;
   animation-duration: ${props => `${props.animDuration}s`};
   animation-name: '${CursorAnim}';
   animation-fill-mode: forwards;
   animation-delay: ${props => `${props.animDelay}s`};
   animation-play-state: ${props => props.animState};
`



const Title = styled.div`
  letter-spacing: 1.25px;
  width: auto;
  color: #C9DDFF;
  background-color: #181818;
  padding: 8px 16px 8px 16px;
`


class Code extends React.Component {

  state = {

  }

  codeRefs = [];

  constructor(props){
    super(props);
    this.timings = this.calcTimings(props.codeRows);
  }


  calcTimings = (codeRows) => {
    let totalDelay = 0;
    return codeRows.map((c, i) => {
      let duration = c.code.length*0.04;
      duration += c.extraDuration ? c.extraDuration : 0;
      const obj = {
        animSteps: c.code.length,
        animDuration: duration,
        animDelay: totalDelay
      };
      totalDelay += duration;
      totalDelay += c.extraDelay ? c.extraDelay : 0;
      return obj;
    });
  }

  render(){
    return (
      <Container style={{...this.props.style}}>
        {this.props.title &&
        <Title>
          {this.props.title}
        </Title>
        }
        <CodeContainer>
          <CodeRow>
            <div style={{color: '#C9DDFF'}}>[IN]</div>
          </CodeRow>
          {this.props.codeRows.map((c, i) => {
            const isTabbed = c.code.length > 1 && c.code[0] === '\t';
            return (
              <CodeRow style={{marginLeft: 4}}
                key={`codeRow${i}`} onAnimationEnd={() => {
                if (c.onFinished){
                  c.onFinished();
                }
              }}>
                <RowNr style={{marginRight: 8}} focused={false}>
                  {i+1}
                </RowNr>
                <AnimatedCodeBlock
                  style={{marginLeft: isTabbed ? 32 : 0}}
                  animState={this.props.animState}
                  animSteps={this.timings[i].animSteps}
                  animDuration={this.timings[i].animDuration}
                  animDelay={this.timings[i].animDelay}>
                  {c.code}
                </AnimatedCodeBlock>
                <Cursor
                  animState={this.props.animState}
                  animDuration={this.timings[i].animDuration}
                  animDelay={this.timings[i].animDelay}
                />
              </CodeRow>
            )
          })}
          {this.props.outRows &&
            <>
            <CodeRow style={{marginTop: 4}}>
              <div style={{color: '#C9DDFF'}}>[OUT]</div>
            </CodeRow>
            {this.props.outRows.map((o, i) => {
              const prevTiming = this.timings[this.timings.length-1];
              return (
                <OutRow
                  key={`outRow${i}`} onAnimationEnd={() => {
                  if (o.onFinished){
                      o.onFinished();
                    }
                  }}
                  animState={this.props.animState}
                  animDuration={1}
                  animDelay={prevTiming.animDelay+prevTiming.animDuration+0.5}
                  style={{marginLeft: 6}}>
                  {o.out}
                </OutRow>
              )
            })}
            </>
          }
        </CodeContainer>
      </Container>

    )
  }

}


export default Code;
