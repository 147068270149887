import React, { Component } from 'react';
import hljs from 'highlight.js/lib/core';
import 'highlight.js/styles/nord.css';
import './override.css'; // override some highlight styles
import python from 'highlight.js/lib/languages/python';
import styled from 'styled-components';

const Code = styled.code`
  font-family: 'Source Code Pro', monospace;
`;

const Pre = styled.pre`
  border-radius: 0px;
  overflow: visible;
  background: transparent;
`;


class CodeBlock extends Component {

  componentDidMount = () => {
    hljs.registerLanguage('python', python);
    this.highlight();

  }

  componentDidUpdate() {
    this.highlight();
  }

  highlight = () => {
      this.ref && hljs.highlightBlock(this.ref);
  }

  render() {
    return (
      <Pre style={{...this.props.style}}>
        <Code ref={r => this.ref = r} className={`python ${this.props.className}`} style={{padding: 0, margin: 0, overflow: 'hidden', background: 'inherit', borderRadius: 0}}>
          {this.props.children}
        </Code>
      </Pre>
    );
  }

}

export default CodeBlock;
